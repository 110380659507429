

















































































































































































































import Vue, { PropType } from "vue";
import Quill from "quill";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import {
  BrandPromotion,
  WebinarAssociation,
  WebinarAvailability,
} from "@/clients/clients";
import WebinarDetailsFormModel from "@/data/from/WebinarDetailsFormModel";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { required } from "vee-validate/dist/rules";
import WebinarPromotionFields from "@/components/forms/Webinars/WebinarPromotionFields.vue";
import WebinarAssociationFields from "@/components/forms/Webinars/WebinarAssociationFields.vue";
import { HealthCareInterests } from "@/providers/HealthCareInterests";

extend("required", { ...required, message: "This field is required" });
setInteractionMode("lazy");

export default Vue.extend({
  name: "WebinarDetailsForm",
  components: {
    WebinarAssociationFields,
    WebinarPromotionFields,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    tags: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  data: (): {
    valid: boolean;
    quill: Quill | null;
    form: WebinarDetailsFormModel;
    rules: { (val: File): boolean }[];
    topics: string[];
  } => ({
    valid: true,
    quill: null,
    form: new WebinarDetailsFormModel(),
    rules: [
      (value: File): boolean => !value || value.size < 2000000,
      (value: File): boolean =>
        !value || /image\/jpeg|image\/jpg|image\/png/.test(value.type),
    ],
    topics: HealthCareInterests,
  }),
  computed: {
    webinarAvailability(): typeof WebinarAvailability {
      return WebinarAvailability;
    },
  },
  mounted() {
    this.quill = new Quill(this.$refs?.editor as Element, {
      modules: {
        toolbar: [
          [{ header: [false, 2, 3, 4, 5] }],
          ["bold", "italic", "underline", "link"],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ align: [] }],
          [{ indent: "-1" }, { indent: "+1" }],
          ["image"],
          ["clean"],
        ],
      },
      placeholder: "Enter webinar information...",
      theme: "snow",
    });
    this.quill.on("text-change", () => {
      const converter = new QuillDeltaToHtmlConverter(
        this.quill?.getContents()?.ops ?? [],
        {}
      );
      this.form.description = converter.convert();
    });
  },
  methods: {
    onSubmit(): void {
      this.$emit("submit", this.form);
    },
    setAssociations(data: {
      associations: WebinarAssociation[];
      newAssociations: {
        name: "";
        logo: File | null;
      }[];
    }): void {
      this.$set(this.form, "associations", data.associations);
      this.$set(this.form, "newAssociations", data.newAssociations);
    },
    setBrandPromotions(data: {
      brandPromotions: BrandPromotion[];
      newBrandPromotions: {
        name: "";
        logo: File | null;
      }[];
    }): void {
      this.$set(this.form, "brandPromotions", data.brandPromotions);
      this.$set(this.form, "newBrandPromotions", data.newBrandPromotions);
    },
  },
});
