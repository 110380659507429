















































































import Vue from "vue";
import CreateWebinarStepDetails from "@/views/webinars/WebinarCreate/CreateWebinarStepDetails.vue";
import CreateWebinarStepSpeakers from "@/views/webinars/WebinarCreate/CreateWebinarStepSpeakers.vue";
import CreateWebinarStepAgendas from "@/views/webinars/WebinarCreate/CreateWebinarStepAgendas.vue";
import { WebinarSettingsAdminResponse } from "@/clients/clients";
import { mapActions } from "vuex";
import AppPageLoading from "@/components/layout/AppPageLoading.vue";
import AppPageBanner from "@/components/layout/AppPageBanner.vue";
import AppPage from "@/components/layout/AppPage.vue";

export default Vue.extend({
  name: "WebinarCreateView",
  components: {
    AppPage,
    AppPageBanner,
    AppPageLoading,
    CreateWebinarStepAgendas,
    CreateWebinarStepSpeakers,
    CreateWebinarStepDetails,
  },
  data: (): {
    step: number;
    previousStep: number;
    settings: WebinarSettingsAdminResponse;
    loading: boolean;
  } => ({
    step: 1,
    previousStep: 0,
    settings: new WebinarSettingsAdminResponse(),
    loading: false,
  }),
  async created(): Promise<void> {
    this.loading = true;
    try {
      this.settings = await this.getSettings();
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions("WebinarUpsert", ["getSettings", "createWebinar"]),
    onNext(): void {
      this.step++;
      this.previousStep = this.step - 1;
    },
    async onSubmit(): Promise<void> {
      this.loading = true;
      try {
        await this.createWebinar();
        await this.$router.push({ name: "Webinars" });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
