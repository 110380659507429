






































































import Vue from "vue";
import WebinarSpeakerForm from "@/components/forms/Webinars/WebinarCreate/WebinarSpeakerForm.vue";
import {
  ImageUploadDto,
  SpeakerAdminResponse,
  SpeakerUpsertRequest,
  WebinarAvailability,
} from "@/clients/clients";
import { mapGetters, mapMutations, mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import { ISelectBoxItems } from "@/data/ISelectBoxItems";

export default Vue.extend({
  name: "CreateWebinarStepSpeakers",
  components: { WebinarSpeakerForm, ValidationObserver },
  data: (): {
    forms: SpeakerUpsertRequest[];
    selectedSpeaker: SpeakerAdminResponse | null;
    existingSpeakers: ISelectBoxItems[];
    loading: boolean;
    error: boolean;
    message: string;
  } => ({
    loading: true,
    error: false,
    message: "",
    forms: [],
    existingSpeakers: [],
    selectedSpeaker: null,
  }),
  computed: {
    ...mapGetters("WebinarUpsert", ["settings", "availability"]),
    ...mapState(["assetsBaseUrl"]),
    requireEmails(): boolean {
      return this.availability === WebinarAvailability.Live;
    },
  },
  created(): void {
    if (this.settings?.speakers?.length) {
      this.existingSpeakers = this.settings.speakers.map(
        (item: SpeakerAdminResponse) => {
          const name = [
            item.title ?? "",
            item.fullName ?? "",
            item.postNominalLetters ?? "",
          ]
            .filter((p) => p !== "")
            .join(" ");
          return { text: name, value: item };
        }
      );
    }
  },
  methods: {
    ...mapMutations("WebinarUpsert", ["setSpeakers", "removeSpeaker"]),
    onSubmit(): void {
      this.setSpeakers(this.forms);
      this.$emit("submit");
    },
    addSpeaker(): void {
      this.forms.push(
        new SpeakerUpsertRequest({
          title: "",
          fullName: "",
          postNominalLetters: "",
          email: "",
          profile: "",
          profileImage: new ImageUploadDto(),
        })
      );
    },
    getExistingSpeaker(
      form: SpeakerUpsertRequest
    ): SpeakerAdminResponse | null {
      if (!form.id) return null;
      const existing = this.existingSpeakers.find(
        (p) => p.value.id === form.id
      );
      return existing?.value ?? null;
    },
    deleteSpeaker(index: number): void {
      this.removeSpeaker(this.forms[index]);
      this.$delete(this.forms, index);
    },
    addSelectedSpeaker(): void {
      if (this.forms.some((p) => p.id === this.selectedSpeaker?.id)) {
        return;
      }
      const existingSpeaker = new SpeakerUpsertRequest();
      existingSpeaker.init(this.selectedSpeaker);
      this.forms.push(existingSpeaker);
      this.selectedSpeaker = null;
    },
  },
});
