












import Vue from "vue";
import WebinarDetailsForm from "../../../components/forms/Webinars/WebinarCreate/WebinarDetailsForm.vue";
import WebinarDetailsFormModel from "@/data/from/WebinarDetailsFormModel";
import { mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  name: "CreateWebinarStepDetails",
  components: { WebinarDetailsForm },
  computed: {
    ...mapGetters("WebinarUpsert", ["settings"]),
  },
  methods: {
    ...mapMutations("WebinarUpsert", ["updateDetails"]),
    onSubmit(form: WebinarDetailsFormModel): void {
      this.updateDetails(form);
      this.$emit("submit");
    },
  },
});
