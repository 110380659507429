















































import Vue from "vue";
import WebinarAgendaForm from "@/components/forms/Webinars/WebinarCreate/WebinarAgendaForm.vue";
import { Agenda, ISpeakerUpsertRequest } from "@/clients/clients";
import { mapGetters, mapMutations } from "vuex";
import { ValidationObserver } from "vee-validate";

export default Vue.extend({
  name: "CreateWebinarStepAgendas",
  components: { WebinarAgendaForm, ValidationObserver },
  data: (): { forms: Agenda[] } => ({
    forms: [new Agenda()],
  }),
  computed: {
    ...mapGetters("WebinarUpsert", ["speakers"]),
    selectedSpeakers(): ISpeakerUpsertRequest[] {
      return this.speakers;
    },
  },
  methods: {
    ...mapMutations("WebinarUpsert", ["setAgenda"]),
    onSubmit(): void {
      const agenda = this.forms.filter((form) => {
        let hasValues = false;
        for (const [_, value] of Object.entries(form)) {
          hasValues = value !== null && value !== undefined;
        }
        return hasValues;
      });
      if (agenda.length) {
        this.setAgenda(agenda);
      }

      this.$emit("submit", agenda);
    },
    addAgenda(): void {
      this.forms.push(new Agenda());
    },
    deleteAgenda(index: number): void {
      this.forms.splice(index, 1);
    },
  },
});
